<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <b-overlay :show="show" rounded="sm">
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(save)">
              <b-row>
                <b-col md="6">
                  <ValidationProvider
                    name="Category Indicator"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      label="Category Indicator"
                      label-for="mc-category-indicator"
                    >
                      <select
                        class="form-control"
                        v-model="model.category_indicator_id"
                        :disabled="$route.meta.action == 'edit'"
                      >
                        <option value="">Pilih Kategori</option>
                        <option
                          v-for="(i, index) in categories"
                          :key="index"
                          :value="i.id"
                        >
                          {{ i.name }}
                        </option>
                      </select>

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    name="Indicator"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="Indicator" label-for="mc-indicator">
                      <select
                        class="form-control"
                        v-model="model.indicator_id"
                        :disabled="$route.meta.action == 'edit'"
                      >
                        <option value="">Pilih Indikator</option>
                        <option
                          v-for="(i, index) in indicators"
                          :key="index"
                          :value="i.id"
                        >
                          {{ i.name }}
                        </option>
                      </select>

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider
                    name="Data Type"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="Data Type" label-for="mc-data-period">
                      <select class="form-control" v-model="model.data_type">
                        <option value="">Pilih Type</option>
                        <option
                          v-for="(i, index) in dataTypes"
                          :key="index"
                          :value="i.value"
                        >
                          {{ i.label }}
                        </option>
                      </select>

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  v-if="
                    model.data_type != null &&
                      model.data_type == 'map' &&
                      $route.meta.action == 'store'
                  "
                  md="6"
                >
                  <ValidationProvider
                    name="Data Unit"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      label="Data Unit"
                      label-for="mc-data-location"
                    >
                      <select class="form-control" v-model="model.data_unit">
                        <option value="">Pilih Unit</option>
                        <option
                          v-for="(i, index) in data_units"
                          :key="index"
                          :value="i.value"
                        >
                          {{ i.label }}
                        </option>
                      </select>

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider
                    name="CSV Data"
                    :rules="$route.meta.action == 'store' ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="CSV Data" label-for="mc-csv-data">
                      <b-form-file
                        v-model="csv"
                        @change="csv_change"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- 
              <b-col md="6">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Status" label-for="mc-status">
                    <b-form-radio-group
                      v-model="model.status"
                      :options="statusList"
                      class="mt-1"
                      value-field="value"
                      text-field="label"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col> -->
              </b-row>
              <b-card-footer>
                <!-- submit and reset -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1 float-right"
                >
                  Simpan
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="mr-1 float-right"
                  variant="outline-secondary"
                  :to="{ name: 'indicator_datas' }"
                >
                  Batal
                </b-button>
              </b-card-footer>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BOverlay,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "UsersForm",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "/indicator_data",
        category: "/category_indicator?orderby=id&sort=desc",
        indicator: "/indicator?orderby=id&sort=desc&category_indicator_id=",
        redirect: "indicator_datas",
      },
      model: {
        indicator_id: "",
        category_indicator_id: "",
        data_unit: "",
        data_type: "",
      },
      csv: null,
      categories: [],
      indicators: [],
      statusList: [
        {
          label: "Aktif",
          value: "active",
        },
        {
          label: "Tidak Aktif",
          value: "inactive",
        },
      ],
      dataTypes: [
        {
          label: "Chart",
          value: "chart",
        },
        {
          label: "Map",
          value: "map",
        },
      ],
      data_units: [
        {
          label: "Province",
          value: "province",
        },
        {
          label: "District",
          value: "district",
        },
      ],
      messages: "",
      show: false,
    };
  },
  mounted() {
    let _ = this;
    _.getData();
    _.getCategory();
  },
  methods: {
    getCategory() {
      let _ = this;
      axios.get(_.config.category).then((response) => {
        let _ = this;
        _.categories = response.data.data.rows;
      });
    },
    getIndicator(id) {
      let _ = this;
      axios.get(_.config.indicator + id).then((response) => {
        let _ = this;
        _.indicators = response.data.data.rows;
      });
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data.data;
            _.model.data_type =
              _.model.data_type == null ? "" : _.model.data_type;
          });
      }
    },
    csv_change(e) {
      let vm = this;
      this.model.csv = e.target.files[0];
    },
    save() {
      let _ = this;
      _.show = true;
      if (this.$route.meta.action == "store") {
        let formData = new FormData();
        formData.append("category_indicator_id", _.model.category_indicator_id);
        formData.append("indicator_id", _.model.indicator_id);
        formData.append("data_type", _.model.data_type);
        formData.append("csv", _.model.csv);
        axios
          .post(_.config.api, formData)
          .then((res) => {
            this.show = false;
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: res.data.message,
              },
            });
          })
          .catch((e) => {
            // console.log(e.response.data.message.errors[0].msg);
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        let formData = new FormData();
        if (this.logos) {
          formData.append(
            "category_indicator_id",
            _.model.category_indicator_id
          );
          formData.append("indicator_id", _.model.indicator_id);
          formData.append("data_type", _.model.data_type);
          formData.append("csv", _.model.csv);
        } else {
          formData.append(
            "category_indicator_id",
            _.model.category_indicator_id
          );
          formData.append("indicator_id", _.model.indicator_id);
          formData.append("data_type", _.model.data_type);
        }

        axios
          .put(_.config.api + "/" + _.$route.params.id, formData)
          .then((res) => {
            this.show = false;
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Update Data Indicator Success",
                text: res.data.message,
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },

  watch: {
    "model.category_indicator_id": function(newVal, oldVal) {
      let _ = this;
      this.getIndicator(newVal);
    },
  },
};
</script>
